<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon" />

    <v-card class="pa-5">
      <v-form v-model="valid" ref="form1">
        <v-row class="mt-1">
          <v-col md="4" sm="12" cols="12">
            <b>Nome Fantasia *</b>
            <v-text-field v-model="object.name" outlined tile dense hide-details class="my-2" :rules="requiredRule"></v-text-field>
          </v-col>
          <v-col md="6" sm="12" cols="12">
            <b>Razão Social *</b>
            <v-text-field v-model="object.socialName" outlined tile dense hide-details class="my-2" :rules="requiredRule"></v-text-field>
          </v-col>
          <v-col md="2" sm="12" cols="12">
            <b>CNPJ *</b>
            <v-text-field v-model="object.document" outlined tile dense hide-details class="my-2" :rules="requiredRule" v-mask="'##.###.###/####-##'" placeholder="__.___.___/____-__"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="3" sm="12" cols="12">
            <b>CEP</b>
            <v-text-field v-model="object.cep" outlined tile dense hide-details class="my-2" placeholder="_____-___"  @keyup="getCep()" v-mask="'#####-###'" ></v-text-field>
          </v-col>
          <v-col md="7" sm="12" cols="12">
            <b>Endereço *</b>
            <v-text-field v-model="object.address" outlined tile dense hide-details class="my-2" :rules="requiredRule"></v-text-field>
          </v-col>
          <v-col md="2" sm="12" cols="12">
            <b>Número</b>
            <v-text-field v-model="object.number" outlined tile dense hide-details class="my-2"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" sm="12" cols="12">
            <b>Bairro</b>
            <v-text-field v-model="object.neighborhood" outlined tile dense hide-details class="my-2"></v-text-field>
          </v-col>
          <v-col md="6" sm="12" cols="12">
            <b>Cidade *</b>
            <v-text-field v-model="object.city" outlined tile dense hide-details class="my-2" :rules="requiredRule"></v-text-field>
          </v-col>
          <v-col md="2" sm="12" cols="12">
            <b>UF *</b>
            <v-text-field v-model="object.state" outlined tile dense hide-details class="my-2" :rules="requiredRule"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="5" sm="12" cols="12">
            <b>Responsável</b>
            <v-text-field v-model="object.actor" outlined tile dense hide-details class="my-2"></v-text-field>
          </v-col>
          <v-col md="3" sm="12" cols="12">
            <b>Telefone *</b>
            <v-text-field v-model="object.phone" outlined tile dense hide-details class="my-2" :rules="requiredRule" v-mask="['(##) ####-####', '(##) #####-####']" placeholder="(__) _____-____"></v-text-field>
          </v-col>
          <v-col md="4" sm="12" cols="12">
            <b>E-mail *</b>
            <v-text-field v-model="object.email" outlined tile dense hide-details class="my-2" :rules="requiredRule"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex mb-6">
            <v-btn color="primary" dark v-on:click="resolveForm()">
              <v-icon small class="mr-2">mdi-content-save</v-icon> Salvar
            </v-btn>
            <span class="ml-auto caption">* Campos obrigatórios</span>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

  </v-container>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'
import TheTitle from "@/components/TheTitle"
import { mask } from "vue-the-mask"
import { mapState } from "vuex"

export default {
  name: "Empresa",
  directives: { mask },

  components: {
    TheTitle,
  },

  computed: {
    ...mapState(["user"])
  },

  data: () => ({
    baseRoute: "company",
    title: "Perfil da Empresa",
    pageIcon: "mdi-office-building-outline",
    requiredRule: [(v) => !!v || "O Campo é necessário"],
    object: {},
    dialog: false,
  }),

  methods: {
    loadData() {
      this.$store.dispatch('setLoadingInfo', true)
      const url = `${baseApiUrl}/${this.baseRoute}/${this.user.idCompany}`
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch('setLoadingInfo', false)
          this.object = res.data
        })
        .catch(showError)
    },

    resolveForm() {
      if (!this.valid) {
        this.$store.dispatch("setErrorInfo", {
          open: true,
          text: "Os campos não foram preenchidos corretamente. Por favor, verifique.",
          button: "Ok!",
        });

        return;
      }

      this.update()
    },

    update() {
      this.error = false
      const id = this.user.idCompany
      const url = `${baseApiUrl}/${this.baseRoute}/${id}`
      const objects = this.object
      delete objects.id

      delete objects.createdAt
      delete objects.updatedAt
      delete objects.deletedAt
      axios
        .put(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },
  },

  mounted() {
    this.loadData()
  },
}
</script>

<style></style>
